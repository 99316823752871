<template>
  <div>
    <v-form
      ref="formSaveHoliday"
      @submit.prevent="saveHoliday"
      class="container"
    >
      <h1>Adicionar Feriado</h1>
      <v-select
        :items="cities"
        item-text="name"
        item-value="id"
        label="Cidade"
        :rules="[(v) => !!v || 'Selecione uma cidade']"
        v-model="holiday.city_id"
        :loading="loading"
      ></v-select>
      <v-text-field
        label="Data"
        type="date"
        :rules="[(v) => !!v || 'Preencha a data']"
        v-model="holiday.date"
      ></v-text-field>

      <v-btn
        :disabled="!holiday.city_id || !holiday.date || loading || saving"
        :loading="loading || saving"
        class="ma-2"
        color="primary"
        type="submit"
      >
        Salvar
      </v-btn>

      <v-btn
        class="ma-2"
        color="error"
        @click="$router.push({ name: 'holidays.index' })"
      >
        Voltar
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getCities();
  },

  data() {
    return {
      cities: [],
      loading: false,
      saving: false,
      holiday: {
        city_id: "",
        date: "",
      },
    };
  },

  methods: {
    async saveHoliday() {
      const form = this.$refs.formSaveHoliday;

      if (form.validate()) {
        this.loading = true;
        try {
          await this.$http.post("api/holidays", this.holiday);
          this.$toast.success("Salvo");
          this.cleanForm();
          form.resetValidation();
        } catch (error) {
          this.$toast.error("Erro ao salvar");
        }
        this.loading = false;
      }
    },

    cleanForm() {
      this.holiday = {
        city_id: "",
        date: "",
      };
    },

    async getCities() {
      this.loading = true;
      try {
        const { data } = await this.$http.get("api/cities");
        this.cities = data;
      } catch (error) {
        this.$toast.error("Erro ao carregar cidades");
      }
      this.loading = false;
    },
  },
};
</script>
